import LanguageSpecificLandingPage from '@/components/LanguageSpecificLandingPage/LanguageSpecificLandingPage';
import contactPersonImageMarta from '@/components/LanguageSpecificLandingPage/lib/marta.png';
import contactPersonImageMarzena from '@/components/LanguageSpecificLandingPage/lib/marzena.png';
import { contactData } from '@/constants/internationalPages';

const pageData = {
  meta: {
    title: 'Rent machines at klickrent: Machine rental for professionals',
    description:
      'Construction vehicles, manlifts, cranes - You can rent everything for your construction project at klickrent ✓ Wide selection ✓ Personal contact for every rental',
  },
  heading1: 'Renting equipment can be this easy',
  contactPersons: [
    {
      id: 1,
      personImageAltText: 'Contact person image',
      personImage: contactPersonImageMarzena,
      flagsAltTexts: {
        pl: 'Polish flag',
        en: 'British flag',
        de: 'German flag',
      },
      heading: 'International Sales',
      name: 'Marzena Kunysz',
      phone: {
        number: contactData.contactPersonPhoneMarzena,
        href: contactData.contactPersonPhoneHrefMarzena,
      },
      hours: 'Monday to Friday - 09:00 to 17:00',
      email: {
        email: contactData.contactPersonEmail,
        href: contactData.contactPersonEmailHref,
      },
    },
    {
      id: 2,
      personImageAltText: 'Contact person image',
      personImage: contactPersonImageMarta,
      flagsAltTexts: {
        pl: 'Polish flag',
        en: 'British flag',
        de: 'German flag',
      },
      heading: 'International Sales',
      name: 'Marta Voigt',
      phone: {
        number: contactData.contactPersonPhoneMarta,
        href: contactData.contactPersonPhoneHrefMarta,
      },
      hours: 'Monday to Friday - 09:00 to 17:00',
      email: {
        email: contactData.contactPersonEmail,
        href: contactData.contactPersonEmailHref,
      },
    },
  ],
  partnersAltTexts: {
    algeco: 'Algeco Modular Buildings',
    ruthmann: 'Ruthmann Manlifts',
    caterpillar: 'Caterpillar Construction Vehicles',
    merlo: 'Merlo Telescope Loaders',
    volvo: 'Volvo Construction Vehicles',
  },
  about: {
    heading: (
      <>
        Our Aim – the perfect
        <br />
        construction equipment package for your needs
      </>
    ),
    body:
      'We want you to be able to concentrate on the important aspects of your work. This is why we want to make it as easy as possible for you to find the perfect construction equipment package for your needs. With klickrent, you get everything from the Offer to the Invoice in one place! klickrent is an independent digital market for renting construction equipment. The online rental platform brings renters and rental companies together and makes your business more efficient. You just have to send your request and then we work with our partner network across all of Germany to make you the perfect offer.',
  },
  becomePartner: {
    heading: 'Become a Partner now',
    body:
      'Professional rental partners meet at klickrent, because that is where the best business is done. If you would also like to benefit from Online-Rentals, our partner management team will be pleased to escort you on your journey to becoming a digital rental company.',
    cta: {
      text: 'Become a partner',
      href: contactData.becomePartnerEmailHref,
    },
  },
  features: {
    easy: "It's that easy!",
    oneContact: (
      <>
        One Account & <br /> Contact person
      </>
    ),
    competence: 'Competence & Experience',
    availability: 'Availability & Speed',
    bestOffer: 'Every time the best offer for you',
  },
  productCatalog: {
    headline: 'Even for bigger plans – we have the right equipment',
    description:
      'For more complex rental projects, we will provide you with a personal expert. Select the category you need and leave your phone number. We will get back to you immediately.',
    productsLabels: {
      cranes: { name: 'Cranes', alt: 'Cranes' },
      containers: { name: 'Containers', alt: 'Containers' },
      powerAndLighting: {
        name: 'Power & Lighting',
        alt: 'Power and Lighting',
      },
      refrigerationAndHeating: {
        name: 'Refrigeration / Heating',
        alt: 'Refrigeration and Heating',
      },
    },
  },
  bottomCTA: {
    email: {
      text: 'Write us an E-Mail',
      href: contactData.contactPersonEmailHref,
    },
    call: {
      text: 'Call Marzena',
      href: contactData.contactPersonPhoneHrefMarzena,
    },
  },
};

export default function InternationalLandingPage() {
  return <LanguageSpecificLandingPage data={pageData} />;
}
